import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './ItemWithImage.css';

export default class ItemWithImage extends Component {
  static propTypes = {
    image: PropTypes.string,
    icon: PropTypes.string,
    iconColor: PropTypes.string,
    href: PropTypes.string,
    title: PropTypes.string.isRequired,
    info: PropTypes.string.isRequired,
    category: PropTypes.string,
    publishedAt: PropTypes.string,
    columns: PropTypes.string
  };

  static defaultProps = {
    columns: 'col-md-6',
    image: null,
    icon: null,
    iconColor: '8f9abd'
  }

  imageOrIcon() {
    if( this.props.icon ) {
      return (
        <div className="lead-icon col-12" style={{color: this.props.iconColor}} >
          <i className={this.props.icon}></i>
        </div>
      )
    } else if ( this.props.image ) {
      return (
        <div className="lead-icon col-12">
          <img src={this.props.image} alt={this.props.title} className="lead-image" />
        </div>
      )
    } else {
      return (
        <div className="lead-icon default-icon col-12">
          <i className="fab fa-whmcs"></i>
        </div>
      )
    }
  }
  
  render() {
    const columns = `${this.props.columns} portfolio-item`
    return (
      <div className={columns}>
        <div className="cbp-item-wrapper col-12">
          <Link to={this.props.href} className="">
            <figure>
              {this.imageOrIcon()}
              <figcaption>
                <div className="category">{this.props.category}</div>
                <div className="title">{this.props.title}</div>
                <div className="info">{this.props.info}</div>
                <div className="date">{this.props.publishedAt}</div>
              </figcaption>
            </figure>
          </Link>
        </div>
      </div>
    )
  }
}
