import React, { Component } from 'react';
import trackingPixelImage from './pixel.jpg';

class TrackingPixel extends Component {
  render() {
    return (
      <img src={trackingPixelImage} alt="blank" />
    );
  }
}

export default TrackingPixel;