import articlesData from 'data/articles.json';
import moment from 'moment';
import _ from 'lodash';

class ArticleRepository {
  constructor() {
    this.articles = [];
    this.categoryList = [];
    this.categoryCount = {};
    
    let jsonArticles = articlesData.map((article) => {
      article.date = moment(article.date);
      return article;
    });

    this.articles = jsonArticles.sort(this.compare)

    this.categoryList = _.uniq(articlesData.map((article) => {
      return article.category
    }))

    const categoryCount = _.countBy(articlesData.map((article) => {
      return article.category
    }));

    this.categoryCount = _.toPairs(categoryCount)
  }

  load(callback) {
    if(this.articles.length > 0) {
      callback(this.articles);
    } else {
      let jsonArticles = articlesData.map((article) => {
        article.date = moment(article.date);
        return article;
      });

      jsonArticles = jsonArticles.sort(this.compare)
      this.articles = jsonArticles;

      callback(jsonArticles);
    }
  }

  categories(callback) {
    if (this.categoryList.length > 0) {
      callback(this.categoryList);
    } else {
      const categories = articlesData.map((article) => {
        return article.category
      })

      this.categoryList = _.uniq(categories)
      callback(this.categoryList);
    }
  }

  categoriesWithCount(callback) {
    if (this.categoryCount.length > 0) {
      callback(this.categoryCount);
    } else {
      const categoryCount = _.countBy(articlesData.map((article) => {
        return article.category
      }));

      this.categoryCount = _.toPairs(categoryCount)
      callback(this.categoryCount)
    }
  }

  compare(a, b) {
    if (a.date < b.date)
      return 1;
    if (a.date > b.date)
      return -1;
    return 0;
  }

}

export default new ArticleRepository();
