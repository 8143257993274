import React from 'react';
import { NavLink } from 'react-router-dom';
import Button from 'Button';

import './Header.css';

export default class Header extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      menuOpen: false,
      contentOffset: 0
    }
  }

  componentDidMount() {
  }

  handleClick(value) {
    const menuEl = window.document.getElementById('hamburger-menu');
    if (this.state.menuOpen) {
      menuEl.style.visibility = 'hidden';
      menuEl.style.opacity = 0;
      this.setState({ menuOpen: false });
    } else {
      menuEl.style.visibility = 'visible';
      menuEl.style.opacity = 1;
      this.setState({ menuOpen: true });
    }

    const hamburgerMenu = window.document.getElementById('hamburger-menu');
    const anchors = hamburgerMenu.getElementsByTagName('a')
    const documentOffset = this.findPos('nav-header');

    for (var i = 0, len = anchors.length; i < len; i++) {
      anchors[i].onclick = () => {
        this.setState({ menuOpen: false });

        const menuEl = window.document.getElementById('hamburger-menu');

        window.scrollTo(0, documentOffset + 40);
        menuEl.style.visibility = 'hidden';
        menuEl.style.opacity = 0;
      }
    }
  }

  findPos(id) {
    var node = document.getElementById(id);
    var curtop = 0;
    var curtopscroll = 0;
    if (node.offsetParent) {
      do {
        curtop += node.offsetTop;
        curtopscroll += node.offsetParent ? node.offsetParent.scrollTop : 0;
        // eslint-disable-next-line
      } while (node = node.offsetParent);

      return (curtop - curtopscroll);
    }
  }

  emailLink() {
    const coded = "0mLY@Am7jpo8p.Pp0.m3";
    const key = "DoqmzkXAEwiULB8CsTuGxpb0WYyKOMdZVtR1Qc3n94Nleav5hgjI2FrSfPJ76H"
    let shift = coded.length
    let link = ""
    let ltr = ""
    for (let i = 0; i < coded.length; i++) {
      if (key.indexOf(coded.charAt(i)) === -1) {
        ltr = coded.charAt(i)
        link += (ltr)
      } else {
        ltr = (key.indexOf(coded.charAt(i)) - shift + key.length) % key.length
        link += (key.charAt(ltr))
      }
    }
    return `mailto:${link}`
  }

  render() {
    return (
      <header className="padding-0 col-md-12">
        <nav id="nav-header">
          <div className="row">
            <div className="col-6">
              <ul className="tabs">
              <li className="tab"><NavLink to="/" className="home-btn" aria-label="home"><i className="fas fa-home" /></NavLink></li>
                {/* <li className="tab"><NavLink activeClassName='is-active' to="/resume">RESUME</NavLink></li> */}
                <li className="tab"><NavLink activeClassName='is-active' to="/article" aria-label="articles">ARTICLES</NavLink></li>
                {/* <li className="tab"><NavLink activeClassName='is-active' to="/portfolio">PORTFOLIO</NavLink></li> */}
                <li className="tab"><NavLink activeClassName='is-active' to="/resume" aria-label="resume">RESUME</NavLink></li>
                {/* <li className="tab"><NavLink activeClassName='is-active' to="/contact">CONTACT</NavLink></li> */}
              </ul>
            </div>
            <div className="col-6">
              <Button href={this.emailLink()} className="d-none d-lg-block bottom_30 icon float-right">
                Hire Me
                  <i className="fas fa-paper-plane" />
              </Button>

              <button className="hamburger float-right d-block d-md-none" onClick={this.handleClick} href="#" aria-label="menu">
                <i className="fa fa-bars" aria-hidden="true"></i>
              </button>
              <div className="d-none d-md-block social-icons float-right">
                <a className="tw" href="https://www.linkedin.com/in/magandolfo/" aria-label="my linkedin"><i className="fab fa-linkedin-in"></i></a>
                <a className="gh" href="https://github.com/markgandolfo" aria-label="my github"><i className="fab fa-github"></i></a>
                <a className="ins" href="https://twitter.com/markgandolfo" aria-label="my twitter"><i className="fab fa-twitter"></i></a>
              </div>
            </div>
          </div>
          <div id="hamburger-menu" className="menuout">
            <div className="menuin">
              <ul className="tabs">
                <li className="tab"><NavLink to="/" className="home-btn" aria-label="home"><i className="fas fa-home" /></NavLink></li>
                {/* <li className="tab"><NavLink activeClassName='is-active' to="/resume">RESUME</NavLink></li> */}
                <li className="tab"><NavLink activeClassName='is-active' to="/article" aria-label="articles">ARTICLES</NavLink></li>
                {/* <li className="tab"><NavLink activeClassName='is-active' to="/portfolio">PORTFOLIO</NavLink></li> */}
                <li className="tab"><NavLink activeClassName='is-active' to="/resume" aria-label="resume">RESUME</NavLink></li>
                {/* <li className="tab"><NavLink activeClassName='is-active' to="/contact">CONTACT</NavLink></li> */}
              </ul>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}
