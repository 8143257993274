import React from 'react';
import './Footer.css';

export default class Footer extends React.Component {
  render() {
    return (
      <footer>
        <div className="col-6 d-none d-sm-block name">Mark Gandolfo</div>
        <div className="col-6 copyright">© 2018 All rights reserved</div>
      </footer>

    );
  }
}

Footer.propTypes = {
};
