import React, { Component } from 'react'
import aussieV8Image from './001-aussiev8.jpg';
import aussieV8CheckerPlate from './background_bottom.png';
import aussieV8ForumImage from './aussiev8-au-forum.jpg';
import sponsorsCorner from './sponsors-corner.jpg';
import './IntroAussieV8PortfolioItem.css';

const style = {
  "portfolioHeader": {
    background: `url(${aussieV8Image}) no-repeat`,
    backgroundSize: 'contain',
    backgroundPosition: "50%",
    height: '280px',
    margin: "20px 0 30px 0"
  },

  "h2": {
    paddingBottom: "20px",
    paddingTop: "15px"
  },

  "h3": {
    fontSize: "18px",
    borderBottom: "1px solid #cccccc",
    paddingBottom: "10px",
    paddingTop: "30px",
    marginBottom: "30px"
  },

  "contentDark": {
    background: `url(${aussieV8CheckerPlate}) repeat`
  },

  "portfolioContent": {
    backgroundColor: '#fefefe',
    padding: "20px",
    color: "black",
    borderRadius: "5px",
    marginBottom: "30px",
    display: "flex",
    justifyContent: "rows",
    fontSize: "16px"
  },

  "portfolioTextContent": {
    padding: "10px",
    paddingRight: "30px",
    fontSize: "16px"
  },

  "portfolioSidebarImage": {
    width: "50%",
    padding: "30px"
  }
}

export class IntroAussieV8PortfolioItem extends Component {
  render() {
    return (
      <div id="portfolioItem">
        <div className="portfolioHeader" style={style.portfolioHeader}></div>
        <div className="contentDark" style={style.contentDark}>
          <div className="portfolioContent" style={style.portfolioContent}>
            <div className="portfolioTextContent" style={style.portfolioTextContent}>
              <h2 style={style.h2}>AussieV8 - Let the pistons do the torquing</h2>
              <p>Where do car enthusiasts go to talk shop, buy and sell parts and organise car events? In 2004 the answer was nowhere. Facebook was still college students and early myspace leavers, and reddit.com wasn't widely known at this point.</p>
              <p>So in a moment of inspiration AussieV8 was born.</p>
              <p>AussieV8 was launched initially with a For Sale forum, a technical section, and Members Lounge for general off-topic chit-chat. We were off to a good start.</p>

              <h3 style={style.h3}>Research</h3>
              <p>Before kicking off the build, I talked to a few friends and family members to understand what conversation they wanted to have with fellow car enthusiasts, based on that I created the first set of forums. For those interested we kicked off with V8 Technical Forum (for technical help), then social cruises section and a buy &amp; sell forum</p>
              <p>Once launched we continue yearly to survey our member base to understand where the community thinks we should grow and expand.</p>
              <p>Once we had an audience, the next step was to begin to engage local business. I began by going door-to-door to anyone that was roughly supporting the automotive industry in order to understand how we could assist in marrying up customers with brands</p>
              <p>This was an important step to ensure AussieV8 wasn't being built only for the consumer but also had some utility being offered to businesses. What I found after talking to mechanics, upholster's, auto-electricians, and panel beaters were that connecting to their audience with a conversation was much more intriguing a proposition than display advertising online or in magazines.</p>

              <p>So the very next section was "Sponsors Corner" which allowed members to ask questions directly to a select few professionals in each of the above professions. It was a smash hit. A lot of questions were asked, and new leads were being presented to these businesses weekly</p>

              <img src={sponsorsCorner} alt="Sponsors Corner" className="img-fluid" />


              <h3 style={style.h3}>Building an Audience</h3>
              <p>Marketing - Advertising on other forums</p>
              <p>Marketing - Link Sharing</p>
              <p>Marketing - Free Stickers!</p>
              <p>Marketing - Keyrings</p>
              <p>Marketing - Set up moderators/admins (community ownership)</p>
              <p>Marketing - Social pages, "Users Garages"</p>

              <h3 style={style.h3}>Commercialisation</h3>
              <div>Decided on advertising model for commercialisation, instead of membership. Needed to test hypothesis that motoring industry would pay for ads </div>
              <p>Visited car dealerships, mechanics, panel beaters, parts dealerships door to door</p>
              <p>Contra deals to get first advertisers onboard</p>
              <p>Once a few joined, many more started to join. $100/month, share of voice</p>
              <p>Larger players then onboarded, i.e car insurance companies, financials, etc. </p>
              <p>Become time consuming, and I was at university. Decided to find a partner</p>
              <p>Interviewed sales position. Tough gig to hire for, decided to partner with an ad-network instead</p>


              <h3 style={style.h3}>Design</h3>
              <p>Wireframed a forum layout inspired by the research I had done to date </p>
              <p>Worked with a local designer to create a logo and website design</p>
              <p>Implemented and opened up to only senior members of the forum to illicit feedback</p>
              <p>Tweaked design and rolled it out to the rest of the forum</p>


            </div>

            <div className="d-sm-none d-md-block portfolioSidebarImage" style={style.portfolioSidebarImage}>
              <img className="img-fluid" src={aussieV8ForumImage} alt="AussieV8 Forum" />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default IntroAussieV8PortfolioItem
