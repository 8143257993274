import React from 'react';
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom';

import './ArticleHeader.css';

export default class ArticleHeader extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    href: PropTypes.string
  }

  static defaultProps = {
    title: "Back",
    href: "/article"
  }

  render() {
    return (
      <div className="row">
        <header className="col-12 article-header">
          <nav>
            <ul className="tabs">
              <li className="tab">
                <NavLink to={this.props.href}>
                  <i className="fas fa-long-arrow-alt-left"></i>
                  {this.props.title}
                </NavLink>
              </li>
            </ul>
          </nav>
        </header>
      </div>
    );
  }
}
