import React, { Component } from 'react'
import getParam from 'libs/getParam';

import ItemWithImage from 'Content/ItemWithImage';
import HorizontalList from 'Content/HorizontalList';
import articleRepository from 'libs/ArticleRepository';

import './Blog.css';

export default class Blog extends Component {
  static defaultProps = {
    location: {
      'search': ''
    },
  }

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);

    this.state = { 
      articles: [],
      shownArticles: [],
      categories: [],
      selectedCategory: getParam('category', decodeURI(this.props.location.search)) || '*'
    }    
  }

  componentDidMount() {
    articleRepository.load((articles) => {
      this.setState({ articles: articles });
      this.filterArticles(this.state.selectedCategory, articles);
    });

    articleRepository.categories(categories => this.setState({ categories: categories }));
  }

  handleClick(value) {
    this.setState({selectedCategory: value});
    this.filterArticles(value);
  }

  filterArticles(filter, articles = this.state.articles) {   
    if(filter === "*") {
      this.setState({ shownArticles: articles })
    } else {
      this.setState({
        shownArticles: articles.filter(article => article.category === filter)
      })
    }
  }

  renderFilters() {
    if(this.state.categories.length > 1) {
      return(
        <div id="filters-container" className="top_15 row">
          <div className="col-12">
            <HorizontalList name="All" onClick={() => this.handleClick("*")} active={this.state.selectedCategory === "*"} />
            {this.state.categories.map((category, index) => {
              return <HorizontalList name={category} key={index} onClick={() => this.handleClick(category)} active={this.state.selectedCategory === category} />
            })}
          </div>
        </div>
      )
    }
  }

  render() {
    return (
      <div id="content">
        <div id="portfolio" className="row">
          <div className="bottom_15 active col-12">
            <section className="row">
              <div className="col-md-12 content-header bottom_15">
                <div className="section-title top_30 bottom_30"><span /><h2>Blog Posts</h2></div>
                {this.renderFilters()}
              </div>

              <div className="top_30 container">
                <div className="row">
                  {this.state.shownArticles.map((article, index) => {
                    let slug = `/article/${article.slug}`
                    return (
                      <ItemWithImage 
                        key={index} 
                        icon={article.icon} 
                        iconColor={article.iconColor}
                        image={article.image}
                        href={slug} 
                        category={article.category}
                        publishedAt={article.date.format("DD MMM YY")}
                        title={article.title}
                        info={article.short_tease} 
                        columns='col-md-4'
                      />
                    )
                  })}
                </div>
              </div>

            </section>
          </div>
        </div>        
      </div>
    )
  }
}
