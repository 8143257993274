import React from 'react';
import './Profile.css';
import ProfileImage from './mark.jpg'

export default class Profile extends React.Component {

  email() {
    const coded = "0mLY@Am7jpo8p.Pp0.m3";
    const key = "DoqmzkXAEwiULB8CsTuGxpb0WYyKOMdZVtR1Qc3n94Nleav5hgjI2FrSfPJ76H"
    let shift = coded.length
    let link = ""
    let ltr = ""
    for (let i = 0; i < coded.length; i++) {
      if (key.indexOf(coded.charAt(i)) === -1) {
        ltr = coded.charAt(i)
        link += (ltr)
      } else {
        ltr = (key.indexOf(coded.charAt(i)) - shift + key.length) % key.length
        link += (key.charAt(ltr))
      }
    }
    return link
  }

  render() {
    return (
      <div className="profile padbot_45">
          <div className="profile-name">
            <span className="name">Mark Gandolfo</span><br />
            <span className="job">Product Manager / Developer</span>
          </div>
          <div className="row">
            <figure className="col-lg-12 col-sm-6 profile-image">
              <img src={ProfileImage} alt="" />
            </figure>
            <ul className="col-lg-12 col-sm-6 profile-information">
                <li><i className="profile-icon fas fa-barcode"></i></li>
                <li><p><span>Name:</span> Mark Gandolfo</p></li>
                <li><p><span>Job:</span> Product Manager</p></li>
                <li><p><span>Email:</span> {this.email()}</p></li>
            </ul>
          </div>

          <div className="col-md-12 download-cv">
          </div>
      </div>
    );
  }
}

Profile.propTypes = {
};
