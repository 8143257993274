import React, { Component } from 'react'
import Article from 'Article';
import PortfolioItemComponent from 'PortfolioItemComponent';
import _ from 'lodash';

import portfolioData from 'data/portfolio.json';


export default class PortfolioItem extends Component {
  constructor(props) {
    super(props);
    const portfolioSlug = this.props.match.params.slug;

    let portfolio = {
      title: 'No Portfolio Entry Found',
      body: '',
      display: 'inline'
    }

    portfolio = _.filter(portfolioData, { slug: portfolioSlug })
    if(Array.isArray(portfolio) && portfolio.length > 0) {
      portfolio = portfolio[0]
    }

    this.state = {
      title: portfolio.title,
      body: portfolio.body,
      categories: portfolio.category,
      display: portfolio.display,
      component: portfolio.component
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentOrInline() {
    if(this.state.display === "component") {
      return(
        <PortfolioItemComponent 
          title={this.state.title}
          portfolioItem={this.state.component}
        />
      )
    } else {
      return <Article
        articleHeaderTitle="Back to Portfolio"
        articleHeaderHref="/portfolio"
        title={this.state.title}
        body={this.state.body}
      />
    }
  }

  render() {
    return (
      this.componentOrInline()
    )
  }
}
