import React, { Component } from 'react';
import PropTypes from 'prop-types'

import Showdown from 'showdown';
import Footer from 'Footer';
import ArticleHeader from './ArticleHeader';
import Category from './CategoryList/Category'

import './Article.css'

const converter = new Showdown.Converter();

export default class Article extends Component {
  static propTypes = {
    articleHeaderTitle: PropTypes.string,
    articleHeaderHref: PropTypes.string,
    title: PropTypes.string.isRequired,
    category: PropTypes.string,
    publishedAt: PropTypes.string,
    body: PropTypes.string.isRequired,
    sidebar: PropTypes.any,
  }

  static defaultProps = {
    articleHeaderTitle: "Back",
    articleHeaderHref: "/article"
  }

  componentDidMount() {
    window.Prism.highlightAll();
    window.scrollTo(0,0);
  }

  componentDidUpdate() {
    window.Prism.highlightAll();
  }

  render() {
    const body = { __html: converter.makeHtml(this.props.body) }
    return (
      <div id="article">
        <ArticleHeader title={this.props.articleHeaderTitle} href={this.props.articleHeaderHref} />

        <div className="row">
          <div className="col-12">
            <div id="content">
              <div className="post">
                <div className="post-content">
                  <div className="post-date">{this.props.publishedAt}</div>
                  <h1 className="article-title bottom_30">{this.props.title}</h1>
                  <div dangerouslySetInnerHTML={body} />
                </div>

                <div className="category">
                  <div className="section-title bottom_15 top_15"><span /><h5>Category</h5></div>
                  <ul className="category-list">
                    <Category name={this.props.category} />
                  </ul>
                </div>

              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      )
    }
  }
