import React from 'react'
import PropTypes from 'prop-types';

import './Button.css'

export default class Button extends React.Component {
  static propTypes = {
    href: PropTypes.string.isRequired,
    className: PropTypes.string,
  }

  render() {
    const style = "btn site-btn " + this.props.className;

    return (
      <a href={this.props.href} className={style}>
        {this.props.children}
      </a>

    )
  }
}
