/*
 TODO
 [x] localstorge needs to set a json object, article key should be html, etc /
 [x] updateState should update local storage
 [x] pull local storage on component load, iterate through keys and set state
 [x] Export button should build full json object
 [x] Date should be auto generated (import moment I guess)
 [x] Slug should be autogenerated based on title
 [ ] Preview should be scrollable (overflow: scroll)
 [-] Export should look like a button
 [x] Export click should bring up an alert with input, to allow selection easily
*/

import React, { Component } from 'react'
import Showdown from 'showdown';
import moment from 'moment';

import './CreateArticle.css';

const converter = new Showdown.Converter();

export class CreateArticle extends Component {
  constructor(props) {
    super(props);
    this.handleInput = this.handleInput.bind(this);
    this.exportButtonClick = this.exportButtonClick.bind(this);
    this.updateState = this.updateState.bind(this);
    this.newButtonClick = this.newButtonClick.bind(this);
    this.showHideMeta = this.showHideMeta.bind(this);
    this.storage = window.localStorage;

    this.state = {
      title: this.storage.getItem('title') || '',
      slug: this.storage.getItem("slug") || '', //2010-08-28 06:22:31
      date: this.storage.getItem("date") || moment().format("YYYY-MM-DD HH:mm:ss"),
      description: this.storage.getItem("description") || '',
      category: this.storage.getItem("category") || '',
      html: this.storage.getItem("html") || '',
      markdown: this.storage.getItem("markdown") || '',
      metaShown: true
    }
  }

  updateState(event) {
    const name = event.currentTarget.name;
    const value = event.currentTarget.value;
    let data = {}
    data[name] = value;

    this.setState(data)
    this.storage.setItem(name, value);

    if(name === "title") {
      this.generateSlug(value);
    }
  }

  generateSlug(title) {
    const slug = title.toString().toLowerCase()
      .replace(/\s+/g, '-')           // Replace spaces with -
      .replace(/[^\w-]+/g, '')       // Remove all non-word chars
      .replace(/--+/g, '-')         // Replace multiple - with single -
      .replace(/^-+/, '')             // Trim - from start of text
      .replace(/-+$/, '');            // Trim - from end of text

    this.setState({ slug })
    this.storage.setItem('slug', slug);
  }


  showHideMeta(event) {
    event.preventDefault();
    if(this.state.metaShown) {
      this.setState({metaShown: false})
      document.getElementById('metaGroup').style.display = 'none'
      document.getElementById('metaToggle').innerHTML = '<i class="fas fa-chevron-down"></i>'
    } else {
      this.setState({ metaShown: true })
      document.getElementById('metaGroup').style.display = 'flex'
      document.getElementById('metaToggle').innerHTML = '<i class="fas fa-chevron-up"></i>'
    }
  }

  exportButtonClick(event) {
    event.preventDefault();
    
    const article = {
      icon: this.state.icon,
      title: this.state.title,
      slug: this.state.slug,
      date: this.state.date,
      description: this.state.description,
      category: this.state.category,
      body: this.state.markdown
    }
    window.prompt(this.state.title, JSON.stringify(article) + ',')
  }

  newButtonClick(event) {
    event.preventDefault();
    this.storage.removeItem('icon');
    this.storage.removeItem('title');
    this.storage.removeItem('slug');
    this.storage.removeItem('date');
    this.storage.removeItem('description');
    this.storage.removeItem('category');
    this.storage.removeItem('html');
    this.storage.removeItem('markdown');
    this.setState({ title: '', slug: '', date: '', description: '', category: '', html: '', markdown: '' })
  }

  handleInput(event) {
    event.preventDefault();

    this.setState({ 
        html: event.currentTarget.value,
        markdown: converter.makeHtml(event.currentTarget.value)
    });
    this.storage.setItem("html", event.currentTarget.value);
    this.storage.setItem("markdown", converter.makeHtml(event.currentTarget.value));
    window.Prism.highlightAll();
  }

  componentDidMount() {
    if (!this.storage.getItem('date')) {
      this.storage.setItem("date", moment().format("YYYY-MM-DD HH:mm:ss"))
    }

    window.Prism.highlightAll();
  }

  componentDidUpdate() {
    window.Prism.highlightAll();
  }

  render() {
    return (
      <div id="create-blog">
        <div className="button">
          <a href="#/#"
             onClick={this.showHideMeta}
             id="metaToggle"
             className="btn float-left">
            <i className="fas fa-chevron-up"></i>
          </a>
          <a href="#/#"
             onClick={this.exportButtonClick}
             className="btn float-right">Export
          </a>
          <a href="#/#"
             onClick={this.newButtonClick}
             className="btn float-right">New Article
          </a>
        </div>

       {/* Form */}
        <div id="metaGroup" className="meta row">
          <div className="form-group col-6">
            <label htmlFor="title" className="col-2">Title</label>
            <input 
              name="title"
              className="col-10"
              value={this.state.title}
              onChange={this.updateState}>
            </input>
          </div>

          <div className="form-group col-6">
            <label htmlFor="slug" className="col-2">Slug</label>
            <input 
              name="slug" 
              className="col-10" 
              value={this.state.slug}
              onChange={this.updateState}>
            </input>
          </div>

          <div className="form-group col-6">
            <label htmlFor="icon" className="col-2">Icon</label>
            <input 
              name="icon" 
              className="col-10" 
              value={this.state.icon}
              onChange={this.updateState}>
            </input>
          </div>

          <div className="form-group col-6">
            <label htmlFor="date" className="col-2">Date</label>
            <input 
              name="date" 
              className="col-10" 
              value={this.state.date}
              onChange={this.updateState}>
            </input>
          </div>
          
          <div className="form-group col-6">
            <label htmlFor="description" className="col-2">Description</label>
            <input 
              name="description" 
              className="col-10" 
              value={this.state.description}
              onChange={this.updateState}>
            </input>
          </div>

          <div className="form-group col-6">
            <label htmlFor="category" className="col-2">Category</label>
            <input 
              name="category" 
              className="col-10" 
              value={this.state.category}
              onChange={this.updateState}>
            </input>
          </div>
        </div>

        {/* Business Time */}
        <div className="wrapper">
          <textarea
            id="markdown-textarea"
            className="markdown"
            onChange={this.handleInput}
            value={this.state.html}>
          </textarea>
          <div 
            className="preview"
            dangerouslySetInnerHTML={{ __html: this.state.markdown}}>
          </div>
        </div>
      </div>
    )
  }
}

export default CreateArticle
