import React from 'react';
import PropTypes from 'prop-types';


class ContentFullWidth extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    content: PropTypes.string,
    classNames: PropTypes.string,
    line: PropTypes.bool
  }

  static defaultProps = {
    line: true
  }

  sectionTitle(){
    if(this.props.title) {
      return(
        <div className="section-title"><span></span><h2>{this.props.title}</h2></div>
      )
    }
  }

  lineClass() {
    if(this.props.line) { return "line"; } 
    return "";
  }

  render() {   
    // line col-12 padding_30 padbot_45
    const classNames = `home_content col-12 ${this.lineClass()} ${this.props.classNames}`;

    return (
        <section className={classNames}>
          {this.sectionTitle()}
          <div className="top_30" >
            {this.props.children}
          </div>
        </section>
      );
  }
}

export default ContentFullWidth;
