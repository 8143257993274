import React, { Component } from 'react'
import ItemWithImage from 'Content/ItemWithImage'
import ContentFullWidth from 'ContentFullWidth'
import Button from 'Button';

import portfolioData from 'data/portfolio.json';

export default class Portfolio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      portfolios: portfolioData
    }
  }
  
  render() {
    return (
      <div id="content">
        <div id="portfolio" className="row bottom_15 active">
          <section className="col-md-12">
            <div className="col-md-12 content-header bottom_15">
              <div className="section-title top_30"><span /><h2>Portfolio</h2></div>
            </div>

            <ContentFullWidth line={false}>
              <p>Designing beautiful experiences, and exciting technology</p>
            </ContentFullWidth>

            <div className="top_30 container">
              <div className="row">
                {this.state.portfolios.map( (portfolio, index) => {
                  let slug = `/portfolio/${portfolio.slug}`
                  return <ItemWithImage 
                    key={index} 
                    category={portfolio.category} 
                    title={portfolio.title} 
                    info={portfolio.description}
                    href={slug}
                    image={portfolio.image} />
                })}
              </div>
            </div>

            <div className="col-xs-12">
              <Button href="/">
                Load More
              </Button>
            </div>
          </section>
        </div>
      </div>
    )
  }
}
