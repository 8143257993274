import React, { Component } from 'react'

import ItemWithImage from 'Content/ItemWithImage';
import articleRepository from 'libs/ArticleRepository';

import './BlogPostSummary.css';

export default class Blog extends Component {
  constructor(props) {
    super(props);

    this.state = { 
      articles: [],
      shownArticles: [],
    }    
  }

  componentDidMount() {
    articleRepository.load((articles) => {
      this.setState({ articles: articles, shownArticles: articles.slice(0,3) });
    });
  }

  render() {
    return (
      <div id="portfolio" className="row articles_home_content">
        <section className="row">
            <div className="top_30 container">
            <div className="row">
              {this.state.shownArticles.map((article, index) => {
                let slug = `/article/${article.slug}`
                return (
                  <ItemWithImage 
                    key={index} 
                    icon={article.icon} 
                    iconColor={article.iconColor}
                    image={article.image}
                    href={slug} 
                    category={article.category}
                    publishedAt={article.date.format("DD MMM YY")}
                    title={article.title}
                    info={article.short_tease} 
                    columns='col-md-4'
                  />
                )
              })}
            </div>
          </div>
          <div className="row">
            <div className="col view_all"> 
              <i className="fas fa-arrow-right"></i> View all <a href="/#/article">articles</a>
            </div>
          </div>
        </section>
      </div>
    )
  }
}
