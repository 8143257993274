import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types'
import './Category.css'


export class Category extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    number: PropTypes.number
  }

  renderNumber() {
    if (this.props.number) {
      return( <span>({this.props.number})</span> )
    } else {
      return ""
    }
  }

  render() {
    return (
      <li className="category-item">
        <Link to={{
          pathname: '/article',
          search: `category=${this.props.name}` 
        }}>{this.props.name}</Link>
        {this.renderNumber()}
      </li>
    )
  }
}

export default Category
