import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './HorizontalList.css';

export class HorizontalList extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    active: PropTypes.bool
  }

  static defaultProps = {
    active: false
  }

  render() {
    const active = this.props.active ? 'active' : '';
    const classes = `${active} cbp-filter-item`;

    return (
      <div onClick={this.props.onClick} className={classes}>{this.props.name}</div>
    )
  }
}

export default HorizontalList
