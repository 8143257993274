import React, { Component } from 'react'
import Article from 'Article';

import articleRepository from 'libs/ArticleRepository';
import articleJson from 'data/articles.json';

export class BlogPost extends Component {
  constructor(props) {
    super(props);
    const articleSlug = this.props.match.params.slug;
    const article = articleJson.find(article => { return article.slug === articleSlug })

    this.state = {
      title: article.title,
      body: article.body,
      publishedAt: article.date,
      category: article.category,
      categoriesWithCount: []
    };
  }

  componentDidMount() {
    articleRepository.categoriesWithCount((categoriesWithCount) => {
      this.setState({ categoriesWithCount: categoriesWithCount })
    })
  }

  render() {
    return (
      <Article
        title={this.state.title}
        publishedAt={this.state.publishedAt.format("DD MMM YY")}
        body={this.state.body}
        category={this.state.category}
      />
    )
  }
}

export default BlogPost
