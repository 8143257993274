import React from 'react';
import MyServices from 'Content/Home/MyServices';
import ContentFullWidth from 'ContentFullWidth';
import BlogPostSummary from 'Content/Home/BlogPostSummary';

import './Home.css'

class Home extends React.Component {
  render() {
    return (
      <div id="content">
        <ContentFullWidth title="Hello" classNames="padding_30 padbot_30" >
        <p>
          I’m a multi-disciplinary technologist. I am a Leader with 10+ years of experience bringing technology products to market as a Product Manager, Development &amp; Delivery Manager, and a Technical Lead.
        </p>

        <p>
          I am driven by the idea that there is a better way to identify business opportunities, to launch new products to market, to run technology-focused teams, and to build innovative software.
        </p>

        <p>
          I have extensive knowledge in the implementation and usage of agile engineering and product development practices, lean product development and design thinking frameworks along with a track record in creating impactful organisational change. 
        </p>

        <p>
          When I’m not building products, you can find me mentoring through <a href="https://www.producthive.org/" alt="Product Hive" target="_blank" rel="noopener noreferrer">Product Hive</a>, riding motorbikes or woodworking, and most importantly being a father<br />
        </p>
        </ContentFullWidth>

        <ContentFullWidth title="Latest Articles" classNames="padding_30 padbot_30" >
          <BlogPostSummary />
        </ContentFullWidth>

        <div className="row">
          <MyServices />
        </div>

        <ContentFullWidth title="Where I Work" classNames="padding_30 padbot_30" >
          <p>
            I am a Product Manager at <a href="https://www.qantas.com/hotels" target="_blank" rel="noopener noreferrer">Qantas Hotels</a>, focused primarily on our customer's post-booking experience. Our mission at Qantas Hotels is to be the default accommodation provider for all Qantas customers.
          </p>
          <p>
            At Qantas Hotels, we are structured in OKR driven product teams. Focus 80% of our time on customer discovery, and 20% on delivery. We empathise with our customers, and we're always in constant pursuit for perfection.
          </p>
        </ContentFullWidth>
      </div>
    );
  }
}

export default Home;
