import React from 'react';
import { Route } from 'react-router-dom';
import Header from 'Content/Header';
import Home from 'Content/Home'
import About from 'About'
import Resume from 'Content/Resume'
import Portfolio from 'Content/Portfolio'
import Blog from 'Content/Blog'
import Profile from 'Profile';
import Footer from 'Footer';

import './Content.css';

export default class Content extends React.Component {
  render() {
    return (
      <div className="row">
        <div className="col-lg-3 col-md-12">
          <Profile />
        </div>
        <div className="col-lg-9 col-md-12">
          <Header />
          <Route exact path="/resume" component={Resume} />
          <Route exact path="/about" component={About} />
          <Route exact path="/portfolio" component={Portfolio} />
          <Route exact path="/article/" component={Blog} />
          <Route exact path="/" component={Home} />
          <Footer />
        </div>
      </div>
    );
  }
}
