import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Footer from 'Footer';
import ArticleHeader from 'Article/ArticleHeader';
import IntroAussieV8PortfolioItem from 'PortfolioItem/IntroAussieV8PortfolioItem';
import NullComponent from 'NullComponent';

import './PortfolioItemComponent.css'

export class PortfolioItemComponent extends Component {
  constructor(props) {
    super(props);

    this.components = {
      IntroAussieV8PortfolioItem: IntroAussieV8PortfolioItem
    }
  }

  static propTypes = {
    title: PropTypes.string.isRequired,
    portfolioItem: PropTypes.any.isRequired
  }

  componentDidMount() {
    document.body.classList.remove('margin-60')
    document.getElementsByClassName('App')[0].classList.add('fullWidth')
  }

  componentWillUnmount() {
    document.body.classList.add('margin-60')
    document.getElementsByClassName('App')[0].classList.remove('fullWidth')
  }

  PortfolioItem() {   
    const PortfolioItem = this.components[this.props.portfolioItem] || NullComponent;
    return (< PortfolioItem />);
  }

  render() {
    return (
      <div id="article">
        <ArticleHeader title="Back to portfolio" href="/portfolio" />
        {this.PortfolioItem()}
        <Footer />

      </div>
    )
  }
}

export default PortfolioItemComponent
