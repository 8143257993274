import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import ContentFullWidth from 'ContentFullWidth';
import HorizontalList from 'Content/HorizontalList';

import Showdown from 'showdown';
import _ from 'lodash';

import 'react-vertical-timeline-component/style.min.css';
import './Resume.css';

import ReeceLogo from './images/reece.png';
import QantasLogo from './images/qantas.png';
import CourseraLogo from './images/coursera.png';
import ApnLogo from './images/apn.png';
import ApgLogo from './images/apg.jpg';
import MocraLogo from './images/mocra.png';
import ActionCoachLogo from './images/actioncoach.png';
import ScrumAllianceLogo from './images/ScrumAlliance.png';
import LaTrobeLogo from './images/LaTrobe.jpg';
import NextElementLogo from './images/NextElement.png';
import antlerLogo from './images/antler.png';

import jsonData from 'data/resume.json';

const converter = new Showdown.Converter();

const WorkIcon = () => {
  return (<i className="fas fa-briefcase"></i>)
}

const EducationIcon = () => {
  return (<i className="fas fa-graduation-cap"></i>)
}

const StarIcon = () => {
  return(<svg className="jss22" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path></svg>)
}

export default class Resume extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.jsonData = this.props.jsonData || jsonData || []

    this.components = {
      work: WorkIcon,
      education: EducationIcon
    }

    this.logos = {
      ReeceLogo,
      QantasLogo,
      ApnLogo,
      ApgLogo,
      MocraLogo,
      ActionCoachLogo,
      CourseraLogo,
      LaTrobeLogo,
      ScrumAllianceLogo,
      NextElementLogo,
      antlerLogo
     }

    this.state = {
      allTimeline: this.jsonData,
      timeline: this.jsonData,
      selectedCategory: '*',
      categories: _.uniq(this.jsonData.map((item) => { return item.type }))
    }
  }

  renderFilters() {
    if (this.state.categories.length > 1) {
      return (
        <div id="filters-container" className="top_30 row">
          <div className="col-12">
            <h4>Filters</h4>
            <p>Use the filters or view my history in full</p>
            <HorizontalList name="All" onClick={() => this.handleClick("*")} active={this.state.selectedCategory === "*"} />
            {this.state.categories.map((category, index) => {
              return <HorizontalList name={category} key={index} onClick={() => this.handleClick(category)} active={this.state.selectedCategory === category} />
            })}
          </div>
        </div>
      )
    }
  }

  handleClick(value) {
    if(value === '*') {
      this.setState({
        timeline: this.state.allTimeline,
        selectedCategory: '*'
      })
    } else {
      this.setState({
        timeline: this.state.allTimeline.filter((item) => { return item.type === value }),
        selectedCategory: value
      })
    }    
  }

  logoComponent(src, alt = "") {
    const LogoComponent = this.logos[src];
    
    if(src) {
      return (
        <div className="col-3">
          <img src={LogoComponent} className="img-fluid" alt={alt} />
        </div>
      )
    }
  }

  Icon(type) {
    const IconComponent = this.components[type] || WorkIcon
    return (< IconComponent />);
  }

  render() {
    return (
      <div id="content">
        <div id="resume">
          <div className="row">
            <section className="col-12">
              <div className="section-title top_30"><span /><h2>Work &amp; Education History</h2></div>
              <div className="row">
                <div className="working-history col-md-12 padding_15 padbot_30">
                  <ContentFullWidth line={false} classNames="padbot_15">
                    <p>I love to build products. I've spent my career tuning my ability to do just that. My early career was spent developing products using a variety of technology including PHP, Ruby, HTML + CSS, and Javascript.</p>
                    <p>Later in my career, I focused on the commercialisation and operationalisation of digital products and motivating teams, so naturally found myself moving into Product Management and Delivery Management roles.</p>
                  </ContentFullWidth>

                  <VerticalTimeline>
                    {this.state.timeline.map((resume, index) => {
                      let content = { __html: converter.makeHtml(resume.content) }

                      return <VerticalTimelineElement
                        key={index}
                        className={resume.className}
                        date={resume.date}
                        iconStyle={resume.iconStyle}
                        icon={this.Icon(resume.type)} >
                        <div className="row">
                          {this.logoComponent(resume.logo)}
                          <div className="col-9">
                            <h3 className="vertical-timeline-element-title">{resume.company}</h3>
                            <h4 className="vertical-timeline-element-subtitle">{resume.title}</h4>
                          </div>
                        </div>
                        <p dangerouslySetInnerHTML={content}></p>
                      </VerticalTimelineElement>
                    })}
                    <VerticalTimelineElement
                      className="timeline-element-last"
                      iconStyle={{ background: 'rgb(16, 204, 82)', fill: '#ffffff' }}
                      icon={<StarIcon />}
                    />
                  </VerticalTimeline>

                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}
