import React, { Suspense, lazy } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import ReactGA from 'react-ga';
import Content from 'Content';
import BlogPost from 'BlogPost'
import PortfolioItem from 'PortfolioItem';
import CreateArticle from 'CreateArticle';
import TrackingPixel from 'Cv/TrackingPixel/TrackingPixel';
import './App.css';

const Cv = lazy(() => import('Cv'))

switch(process.env.NODE_ENV) {
  case 'production':
    ReactGA.initialize('UA-89551120-1');
    break;
  default:
    ReactGA.initialize('UA-89551120-2');
}

(function () {
  var previousUrl = window.location.hash
  setInterval(function () {
    if (previousUrl !== window.location.hash) {
      previousUrl = window.location.hash;
      myCallback();
    }
  }, 100);
})();

function myCallback() {
  ReactGA.pageview(window.location.pathname + window.location.hash.replace('#/', '') + window.location.search);
}

class App extends React.Component {
  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.hash.replace('#/', '') + window.location.search);
  }

  render() {
    return (      
      <HashRouter>
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Route exact path="/t/pixel.jpg" component={TrackingPixel} />
            <Route exact path="/hello/:company" component={Cv} />
          </Switch>
            <div className="App wrapper container">
              <Route exact path="/create/article" component={CreateArticle} />
              <Route exact path="/article/:slug" component={BlogPost} />
              <Route exact path="/portfolio/:slug" component={PortfolioItem} />
              <Route path="/" component={Content} />
            </div>
        </Suspense>
        <div data-attribute={process.env.NODE_ENV}></div>
      </HashRouter>
    );
  }
}

export default App;
