import React, { Component } from 'react';
import MyService from 'Content/Home/MyServices/MyService';
import myServicesData from 'data/myservices.json';

export default class MyServices extends Component {
  render() {
    return (
      <section className="services line graybg col-lg-12 padding_50 padbot_50">
        <div className="section-title bottom_30"><span></span><h2>How I Work</h2></div>
        <div className="row">
          {myServicesData.map((item, index) => {
            return <MyService key={index} title={item.title} description={item.description} icon={item.icon} />
          })}
        </div>
      </section>
    );
  }
}
