import React, { Component } from 'react'
import PropTypes from 'prop-types';

import './MyService.css';

export default class MyService extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    icon: PropTypes.string
  };

  render() {
    return (
      <div className="col-md-6 col-xs-12">
        <div className="service">
          <div className="icon col-3">
              <i className={this.props.icon}></i>
          </div>
          <div className="service-title">{this.props.title}</div>
          <p className="little-text">{this.props.description}</p>
        </div>
      </div>
    )
  }
}
